<template>
	<div style="padding: var(--page-padding); height: var(--content-height)">
		控制台
	</div>
</template>

<script>
export default {
	data() {
		return { }
	},
	mounted() { },
	components: { },
	methods: { }
}
</script>

<style>

</style>